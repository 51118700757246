<template>
  <div class="page-color-palette page-color-palette-gE7Fbq6mRC">
    <h2>Color Palette</h2>

    <div class="color-boxes squared-color-boxes-grid">
      <div
        class="box"
        v-for="(color, index) in colorPalette"
        :key="index"
        :style="{ backgroundColor: color }"
      >
        <div class="hover" @click="deleteColor(index)">
          <IcoBin></IcoBin>
        </div>
      </div>
      <div class="box" style="border-color: transparent">
        <custom-color-picker @change="addColorToPalette">
          <el-button type="text" size="mini" class="btn-add">
            <i class="el-icon-plus"></i>
          </el-button>
        </custom-color-picker>
      </div>
    </div>

    <div>
      <img
        class="img-empty-palette"
        :src="`${publicPath}assets/bg-color-scheme.svg`"
        alt=""
      />
      <br />

      <div
        v-if="colorPalette.length === 0"
        class="align-center justify-center d-flex d-flex-column"
      >
        <p class="mt1">
          You haven't added any colors to your palette yet. Add now!
        </p>
        <extended-el-color-picker
          show-alpha
          size="medium"
          @change="addColorToPalette"
          v-model="defaultColor"
        ></extended-el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import customColorPicker from "@/components/common/custom-color-picker/custom-color-picker.component";
import IcoBin from "@/components/common/ico/delete-24px.svg";

export default {
  components: {
    "custom-color-picker": customColorPicker,
    IcoBin,
  },
  data() {
    return {
      defaultColor: "#66B0F8",
      isLoadingFonts: false,
    };
  },
  computed: {
    ...mapGetters("authModule", ["userInfo"]),
    ...mapActions("authModule", ["getUserInfo"]),
    ...mapActions("brandModule", ["updateColorPalette"]),
    colorPalette: function() {
      return this.userInfo?.color_palette?.length
        ? this.userInfo?.color_palette
        : [];
    },
  },
  mounted: function() {},
  methods: {
    updatePalette: function(newPalette) {
      this.$wait.start("savingPalette");

      this.$store
        .dispatch("brandModule/updateColorPalette", {
          palette: newPalette,
          userId: this.userInfo._id,
        })
        .then(() => {
          this.$wait.end("savingPalette");
          this.$store.dispatch("authModule/getUserInfo", this.userInfo._id);
        })
        .catch((e) => {
          this.$wait.end("savingPalette");
          console.error(e);
        });
    },
    addColorToPalette: function(color) {
      const existingPalette = this.colorPalette.slice();
      const newPalette = existingPalette.concat(color);
      this.updatePalette(newPalette);
    },
    deleteColor: function(index) {
      this.$confirm("This will delete the color. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const palette = this.colorPalette.slice();
          palette.splice(index, 1);
          this.updatePalette(palette);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "color-palette.component.scss";
.squared-color-boxes-grid {
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 15px;
  .box {
    position: relative;
    height: unset;
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .hover {
      opacity: 0;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }
}

.box::before {
  content: "";
  float: left;
  padding-top: 100%; /* initial ratio of 1:1*/
}
@media screen and (max-width: 1100px) {
  .squared-color-boxes-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}
.img-empty-palette {
  opacity: 0.95;
  display: block;
  margin: 0 auto;
  height: 260px;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100vh - 280px);
}
</style>

<style lang="scss">
.page-color-palette-gE7Fbq6mRC {
  .color-boxes {
    .el-color-picker {
      height: 0 !important;
    }

    .hover {
      svg {
        fill: #fff;
        transform: scale(1.5);
      }
    }
  }

  .btn-add {
    color: rgba(0, 0, 0, 0.9) !important;

    &:hover {
      color: $color-secondary;
    }
  }
  .el-color-picker--medium .el-color-picker__trigger {
    height: 56px;
    width: 56px;
  }
  .color-boxes .custom-color-picker {
  }
  .color-boxes .custom-color-picker > div:first-child,
  .color-boxes .custom-color-picker {
    height: unset !important;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
